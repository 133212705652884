<template>
  <div :class="['xybutton', type, disabled ? 'disabled' : '']" @click="handleClick">
    <div class="icon" v-if="type"></div>
    <div><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'Xybutton',
  props: {
    type: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean
    }
  },
  methods: {
    handleClick (evt) {
      if (this.disabled) return
      this.$emit('click', evt)
    }
  }
}
</script>

<style scoped lang="scss">
  .xybutton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    @include font(13px, #666, center);
    cursor: pointer;
    &:hover {
      color: #333;
    }
    .icon {
      background-size: 100% 100%;
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
  .add {
    background: linear-gradient(to right, #6DBBFC, #309AF2);
    border-color: transparent;
    color: #fff;
    &:hover {
      background: #309AF2;
      color: #fff;
    }
    .icon {
      background-image: url("../../assets/add-3.png");
    }
  }
  .del {
    background: linear-gradient(to right, #EFEFEF, #F6F6F6);
    border-color: #E5E5E5;
    color: #FF3C30;
    &:hover {
      background: #F1F1F1;
      color: #FF3C30;
    }
    .icon {
      background-image: url("../../assets/del.png");
    }
  }
  .disabled {
    background: #C0E0FB !important;
    &:hover {
      color: #fff;
    }
  }
</style>
