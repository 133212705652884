import request from "../utils/https";

/**
 * 获取验证码
 * @param {string} aliYunSessionId
 * @param {string} aliYunSig
 * @param {string} aliYunToken
 * @param {string} aliYunScene
 * @param {string} teacherPhoneNumber
 * @returns {AxiosPromise}
 */
export function getAuthCode(aliYunSessionId, aliYunSig, aliYunToken, aliYunScene, teacherPhoneNumber) {
  return request({
    url: '/apiv2/teacher/sms/sendSmsCode',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      aliYunSessionId, aliYunSig, aliYunToken, aliYunScene, teacherPhoneNumber
    }
  })
}

/**
 * 登录
 * @param teacherPhoneNumber
 * @param password
 * @returns {AxiosPromise}
 */
export function login(teacherPhoneNumber, password) {
  return request({
    url: '/apiv2/teacher/login',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      teacherPhoneNumber,
      password
    }
  })
}

/**
 * 通过token静默登录
 * @param id
 * @returns {AxiosPromise}
 */
export function silentLogin(id) {
  return request({
    url: '/apiv2/teacher/getTeacherById',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      id
    }
  })
}

/**
 * 登出
 * @return {AxiosPromise}
 */
export function logout() {
  return request({
    url: '/apiv2/teacher/logout',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
  })
}

/**
 * 重置密码
 * @param {string} verificationCode
 * @param {string} teacherPhoneNumber
 * @param {string} smsId
 * @param {string} teacherPhoneNumber
 * @param {string} password
 * @returns {AxiosPromise}
 */
export function resetPassword(verificationCode, teacherPhoneNumber, smsId, password) {
  return request({
    url: '/apiv2/teacher/forgetPassword',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      verificationCode,
      teacherPhoneNumber,
      smsId,
      newPassword: password
    }
  })
}

/**
 * 获取阿里云滑块初始化参数
 * @param tag 接口文档上的 url 片段 http://192.168.3.235:3000/project/23/interface/api/646
 * @param teacherPhoneNumber
 * @returns {AxiosPromise}
 */
export function getAliYunConfigParam(tag, teacherPhoneNumber) {
  return request({
    url: `/apiv2/teacher/risk/${tag}/getAliYunConfigParam`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      teacherPhoneNumber
    }
  })
}
