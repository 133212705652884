/**
 * 去除时间字符串中的 T
 * @param {string} date
 */
export function trimT(date) {
  if (typeof date === 'string') {
    return date.replace('T', ' ');
  }
  return date
}

/**
 * 返回年级对应的名称
 * @param {String|Number} id
 * @return {*}
 */
export function formatGradeName(id) {
  const grade = {
    0: '六年级',
    1: '七年级',
    2: '八年级',
    3: '九年级',
    4: '高一',
    5: '高二',
    6: '高三',
    7: '初中',
    8: '高中',
  }
  return grade[id]
}
