<template>
  <div class="link-btn">
    <div><slot></slot></div>
    <img class="icon" src="../../assets/z-3.png" alt="z">
  </div>
</template>

<script>
export default {
  name: 'LinkButton'
}
</script>

<style scoped lang="scss">
  .link-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0 14px;
    border: 1px solid #309AF2;
    border-radius: 4px;
    font-size: 14px;
    color: #309AF2;
    cursor: pointer;
    transition: all .3s;
    .icon {
      width: 4px;
      height: 7px;
      margin-left: 6px;
    }
    &:hover {
      background-color: #ecf5ff;
    }
  }
</style>
