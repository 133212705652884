<template>
  <header class="header" v-if="!hiddenGlobalNav" >
    <div class="container">
      <div class="logo" @click="goHome"><img src="../../assets/logo-3.png" alt="logo"></div>
      <NavMenu />
      <div class="user" @mouseenter="subNavShow" @mouseleave="subNavShow">
        <div class="head"><img :src="head" alt="head" v-if="head"/></div>
        <div>
          <div class="name">{{ userInfo.teacherName || '游客' }}</div>
          <div class="school">{{ userInfo.schoolName || '游客' }}</div>
        </div>
        <transition name="bounce">
          <div class="subnav" v-show="navShow">
            <div class="column" @click="set">
              <div class="icon">
                <img src="../../assets/home/set.png" alt="set">
              </div>
              <div>账号设置</div>
            </div>
            <div class="column" @click="logout">
              <div class="icon">
                <img src="../../assets/home/logout.png" alt="logout">
              </div>
              <div>退出登录</div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
import {logout} from "@/api/login";
import NavMenu from '@/layout/components/NavMenu/index.vue'

export default {
  name: 'Header',
  components: {
    NavMenu
  },
  data() {
    return {
      hiddenGlobalNav: false,
      head: null,
      name: '游客',
      schoolName: '游客',
      navShow: false
    }
  },
  created() {
    this.hiddenGlobalNav = sessionStorage.getItem('hiddenGlobalNav')
    this.getInfo()
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  methods: {
    // 显示/隐藏弹框
    subNavShow() {
      this.navShow = !this.navShow
    },
    // 获取信息
    getInfo() {
      this.head = sessionStorage.getItem('teacherHead')
    },
    // 账号设置
    set() {
      this.$router.push({name: 'manage'})
    },
    // 去首页
    goHome() {
      this.$router.push({name: 'home'})
    },
    // 退出登录
    async logout() {
      try {
        await logout()
        this.$store.commit('clearInclude')
        this.$store.commit('setUserInfo', {})
        sessionStorage.clear()
        this.common.globalData.gradeTypeId = null
        this.common.globalData.provinceId = null
        this.common.globalData.cityId = null
        await this.$router.push({name: 'login'})
      } catch (e) {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-color: #FFFFFF;
  width: 100%;
  min-width: 1268px;
  height: 80px;
  border-bottom: 1px solid #E5E5E5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .container {
    @include flex(space-between);
    max-width: 1268px;
    margin: 0 auto;

    .logo {
      img {
        width: auto;
        height: 79px;
      }
    }

    .user {
      @include flex;
      height: 80px;
      @include font(16px);
      position: relative;
      cursor: pointer;

      .head {
        background-color: #F6F6F6;
        width: 50px;
        height: 50px;
        border: 1px solid #E6E6E6;
        border-radius: 25px;
        margin-right: 14px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .name{
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .school {
        font-size: 12px;
        margin-top: 3px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .subnav {
        background-color: #fff;
        width: 154px;
        padding: 10px 0;
        border-radius: 8px;
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .15);
        position: absolute;
        bottom: -95px;
        left: 0;
        z-index: 2006;

        .column {
          @include flex(flex-start);
          padding: 10px 0 10px 28px;
          font-size: 17px;
          cursor: pointer;

          &:hover {
            background-color: #F6F6F6;
          }

          .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1268px) {
  .header{
    min-width: 1120px;
    .container{
      justify-content: center;
      max-width: none;

    }
  }
}
</style>
