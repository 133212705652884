<template>
  <el-menu
    active-text-color="#309af2"
    :default-active="activeMenu"
    class="el-menu-demo"
    mode="horizontal"
    @select="handleSelect"
  >
    <template v-for="route in routes">
      <nav-menu-item class="menu-item-box" :key="route.path" :item="route" :base-path="route.path"/>
    </template>
  </el-menu>
</template>

<script>

import NavMenuItem from '@/layout/components/NavMenu/Item.vue'
import { constantRoutes } from '@/router/index.js'
export default {
  name: 'NavMenu',
  components: {
    NavMenuItem
  },
  data() {
    return {}
  },
  computed: {
    routes() {
      return constantRoutes
    },
    activeMenu() {
      const route = this.$route
      const {meta, path} = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = {...parent, path: '', noShowingChildren: true}
        return true
      }

      return false
    },
    menuPermission(item) {
      let superAdmin = sessionStorage.getItem('superAdmin')
      if (item.admin) {
        return superAdmin === '2'
      } else {
        return !item.hidden
      }
    },

    // 选择目录
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.el-menu{
  &.el-menu-demo{
    border-bottom: 0;
    .el-menu-item{
      font-size: 18px;
      line-height: 25px;
      &.is-active{
        font-weight: bold;
        background-color: #FFFFFF;
        color: #309AF2;
      }
      &:hover{
        font-weight: bold;
        background-color: #FFFFFF;
        color: #309AF2;
      }
    }
  }
}
.menu-item-box{
  display: inline-block;
}
</style>
