import axios from 'axios'
import {Message} from 'element-ui'
import router from '../router/index.js'
import qs from 'qs'
import md5 from 'js-md5'
import common from "@/utils/utils";
import {getUUID, setUUID} from "@/utils/local";

// 取消请求
const request = axios.create({
  baseURL: process.env.VUE_APP_HOST,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

// 开始请求设置，发起拦截处理
request.interceptors.request.use(config => {
  let sessionId = sessionStorage.getItem('sessionId')
  let teacherId = sessionStorage.getItem('teacherId')
  const version = '3.0.0'
  const device = 'web'
  const dataParam = {
    appVersion: version,
    belong: '1',
    device
  }
  if (sessionId && sessionId !== 'null' && !config.data?.sessionId) {
    dataParam.sessionId = sessionId
  }
  if (teacherId && teacherId !== 'null' && !config.data?.teacherId) {
    dataParam.teacherId = teacherId
  }
  let signStr = ''
  if (config.method == 'post') {
    if (config.headers['Content-Type'] === 'application/json') {
      config.data = {
        ...config.data,
        ...dataParam
      }
    } else if (config.headers['Content-Type'] !== 'multipart/form-data') {
      config.data = qs.stringify({
        ...qs.parse(config.data),
        ...dataParam
      })
    } else {
      Object.keys(dataParam).forEach((k) => {
        config.data.append(k, dataParam[k])
      })
    }
    signStr = JSON.stringify(config.data)
  } else if (config.method == 'get') {
    config.params = {
      ...dataParam,
      ...config.params
    }
    signStr = JSON.stringify(config.params)
  }
  let sign
  if (config.url.includes('apiv2')) {
    sign = md5(signStr + '_Sts100#@')
  } else {
    sign = md5(md5(signStr))
  }
  let udid = getUUID()
  if (!udid) {
    udid = common.getUUID(32)
    setUUID(udid)
  }
  config.headers = {
    ...config.headers,
    udid,
    sign,
    release: version,
    device,
    apps: 2,
    thirdTypeId: 1,
    authToken: sessionId
  }
  return config
}, error => {
  console.log(error)
  return Promise.reject(error)
})

// respone拦截器
request.interceptors.response.use(
  response => {
    // if (process.env.NODE_ENV === 'development') {
    //     console.log('----- ' + response.config.method + ' request -----')
    //     console.log(`${location.protocol}${process.env.VUE_APP_HOST}${response.config.url}`)
    //     if (response.config.params) console.log(response.config.params)
    //     if (response.config.data) console.log(response.config.data)
    //     console.log(JSON.stringify(response.data))
    //     console.log('----- response -----')
    // }

    const res = response.data
    // 这里根据后台返回来设置
    if (res.state || res.state === 0) {
      res.state += ''
      if (res.state.toString() === '11') {
        return res
      } else if (res.state.toString() === '22') {
        sessionStorage.clear()
        Message.error('登陆过期，请重新登录！')
        router.replace({'name': 'login'})
      } else {
        if (res.note) {
          Message.error(res.note)
        } else if (res) {
          window.location.href = res
        }
        return res
      }
    } else {
      return response
    }
  },
  error => {
    Message.error('网络异常，请退出重试！')
    console.log(error)
    return Promise.reject(error)
  }
)

export default request
