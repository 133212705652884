import Vue from 'vue'
import Vuex from 'vuex'

import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)
export const store = new Vuex.Store({
    plugins: [persistedState({
        reducer(val) {
            return {
                selectedWorkList: val.selectedWorkList,
                examWorkList: val.examWorkList,
                routerList: val.routerList,
                userInfo: val.userInfo
            }
        }
    })],
    state: {
        keepAliveInclude: [],
        selectedWorkList: {},
        examWorkList: {},
        routerList: [],
        haveClass: '1',
        userInfo: {}
    },
    mutations: {
        haveClassChange: (state, val) => {
            state.haveClass = val
        },
        includeDel: (state, string) => {
            const index = state.keepAliveInclude.indexOf(string)
            if (index !== -1) {
                state.keepAliveInclude.splice(index, 1)
            }
        },
        includeAdd: (state, string) => {
            const index = state.keepAliveInclude.indexOf(string)
            if (index === -1) {
                state.keepAliveInclude.push(string)
            }
        },
        includeChange: (state, string) => {
            const index = state.keepAliveInclude.indexOf(string)
            if (index === -1) {
                state.keepAliveInclude.push(string)
            } else {
                state.keepAliveInclude.splice(index, 1)
            }
        },
        clearInclude: (state) => {
            state.keepAliveInclude = []
        },
        setUserInfo: (state, data = {}) => {
            state.userInfo = data
        },
        updateUserInfo: (state, data = {}) => {
            state.userInfo = { ...state.userInfo, ...data}
        },
        listAdd: (state, obj) => {
            let workList = obj.exam ? state.examWorkList : state.selectedWorkList
            const {id} = obj
            const newObj = {}
            newObj[id] = obj
            workList = Object.assign({}, workList, newObj)
            if (obj.exam) {
                state.examWorkList = workList
            } else {
                state.selectedWorkList = workList
            }
        },
        listDel: (state, obj) => {
            let workList = obj.exam ? state.examWorkList : state.selectedWorkList
            delete workList[obj.id]
            workList = Object.assign({}, workList)
            if (obj.exam) {
                state.examWorkList = workList
            } else {
                state.selectedWorkList = workList
            }
        },
        listClear: (state, type) => {
            if (type) {
                state.examWorkList = {}
            } else {
                state.selectedWorkList = {}
            }
        },
        routerChange: (state, obj) => {
            if(obj && obj.toName === 'transfer' || obj.name === 'transfer' ){
                return
            }
            if (obj.name) { // 无名之辈，休想加入面包屑
                const routerList = state.routerList.map(item => item)
                // 将新来的路由添加到路由列表的最后
                routerList.push(obj)
                // 临时的路由列表
                let routerListTemp = []
                // 对路由列表进行遍历，并将符合条件的路由放至临时路由列表中
                for (let i = 0, len = routerList.length; i < len; i += 1) {
                    // 解决问题：页面进行返回操作后，再次进入相同页面，路由重复的问题
                    if (obj.toName === routerList[i].toName) {
                        routerListTemp.push(routerList[i])
                        break
                    }
                    // 解决问题：如果进入的页面跟当前路由一致，那么到当前位置即可，后面的路由也不需要了
                    if (obj.toName === routerList[i].routerName) {
                        break
                    }
                    // 符合条件的路由，放至临时路由列表中
                    routerListTemp.push(routerList[i])
                }
                state.routerList = routerListTemp
            }
        },
        routerClear: (state) => {
            state.routerList = []
        }
    },
})
