<template>
  <div id="app">
    <keep-alive :include="$store.state.keepAliveInclude">
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
body {
  background-color: #F6F6F6;
  margin: 0;
}

.el-message {
  z-index: 5000 !important;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-enter {
  transform: translateX(-100px);
  opacity: 0;
}

.bounce-enter-active, .bounce-leave-active {
  transition: all .3s ease;
}

.bounce-enter {
  transform: translateY(10px);
}

.bounce-leave-to {
  animation: bounce-in .3s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.slide-enter-active, .slide-leave-active {
  transition: all .3s ease;
}

.slide-enter, .slide-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: auto;
  color: #333333;
  margin: 0;
}
</style>
