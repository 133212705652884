<template>
  <div class="index">
    <Header></Header>
    <div class="container" :style="{marginTop : hiddenGlobalNav ? 0 : '80px'}">
      <div class="content">
        <keep-alive :include="$store.state.keepAliveInclude">
          <router-view/>
        </keep-alive>
      </div>
    </div>
    <el-backtop target="#app">
      <div class="backtop">
        <div class="backtop-img">
          <img src="../assets/top.png" alt="top">
        </div>
        <div class="backtop-value">回到顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import Header from '@/layout/components/header.vue'

export default {
  name: 'index',
  data() {
    return {
      hiddenGlobalNav: false
    }
  },
  components: {
    Header
  },
  mounted() {
    this.hiddenGlobalNav = sessionStorage.getItem('hiddenGlobalNav')
  },
  methods: {
    backtopHover() {
      // this.right = 18
    },
    backtopBlur() {
      // this.right = -21
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  //margin-top: 80px;
  min-width: 1268px;
  padding-left: calc(100vw - 100%);
  .content {
    flex-grow: 1;
    max-width: 1100px;
  }
}

.el-backtop {
  transition: all .3s;
  width: 56px;
  height: 76px;
  z-index: 100;
}

.backtop {
  text-align: center;
  background-color: #fff;
  width: 56px;
  height: 76px;
  padding: 6px 8px 10px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
  font-size: 15px;
  &:hover{
    .backtop-img {
      background: linear-gradient(360deg, #FFFFFF 0%, #F6F6F6 100%);
    }
  }
  .backtop-value{
    margin: 0 6px;
    width: 2em;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
  }

  .backtop-img{
    border-radius: 6px;
    img {
      width: 12px;
      height: 12px;
    }
  }
}

.el-backtop {
  position: fixed;
  bottom: 40px !important;
  right: 14px !important;
}

@media screen and (min-width: 1268px) {
  .el-backtop {
    right: calc(50% - 550px - 70px) !important;
    bottom: 40px !important;
  }
}
</style>
