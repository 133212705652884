import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'

import utils from '../utils/utils.js'
import {store} from '@/store/store'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  {
    path: '/transfer',
    name: 'transfer',
    component: () => import('@/views/transfer/transfer'),
    meta: {title: '静默登录'},
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home'),
        meta: {title: '首页', icon: 'home'}
      },
    ]
  },
  {
    path: '/homework',
    component: Layout,
    redirect: '/homework/assign',
    meta: {title: '作业', icon: 'homework'},
    children: [
      {
        path: 'assign',
        name: 'assignWork',
        component: () => import('@/views/homework/assignWork'),
        meta: {title: '布置作业'}
      },
      {
        path: 'check',
        name: 'checkWork',
        component: () => import('@/views/homework/checkWork'),
        meta: {title: '检查作业'}
      },
      {
        path: 'assign/paperDetail',
        name: 'paperDetail',
        component: () => import('@/views/homework/assign/paperDetail'),
        meta: {title: '音标学习、趣味配音、地区专版、微技能特训、听力强化'},
        hidden: true
      },
      {
        path: 'assign/unitWord',
        name: 'unitWord',
        component: () => import('@/views/homework/assign/unitWord'),
        meta: {title: '智能单词'},
        hidden: true
      },
      {
        path: 'assign/repeatAfter',
        name: 'repeatAfter',
        component: () => import('@/views/homework/assign/repeatAfter'),
        meta: {title: '课文听读、时文阅读'},
        hidden: true
      },
      {
        path: 'assign/written',
        name: 'written',
        component: () => import('@/views/homework/assign/written'),
        meta: {title: '我的教辅'},
        hidden: true
      },
      {
        path: 'assign/writtenSelect',
        name: 'writtenSelect',
        component: () => import('@/views/homework/assign/writtenSelect'),
        meta: {title: '我的教辅-选题布置'},
        hidden: true
      },
      {
        path: 'assign/writtenReview',
        name: 'writtenReview',
        component: () => import('@/views/homework/assign/writtenReview'),
        meta: {title: '我的教辅-试题预览'},
        hidden: true
      },
      {
        path: 'check/bagWorkList',
        name: 'bagWorkList',
        component: () => import('@/views/homework/check/bagWorkList'),
        meta: {title: '作业任务详情'},
        hidden: true
      },
      {
        path: 'check/bagWorkSet',
        name: 'bagWorkSet',
        component: () => import('@/views/homework/check/bagWorkSet'),
        meta: {title: '编辑作业任务'},
        hidden: true
      },
      {
        path: 'check/workDetail',
        name: 'workDetail',
        component: () => import('@/views/homework/check/workDetail'),
        meta: {title: '子作业详情'},
        hidden: true
      },
      {
        path: 'check/studentReport',
        name: 'WorkStudentReport',
        component: () => import('@/views/homework/check/workStudentReport'),
        meta: {title: '学生报告'},
        hidden: true
      },
      {
        path: 'check/studentReportIframe',
        name: 'WorkStudentReportIframe',
        component: () => import('@/views/homework/check/workStudentReportIframe'),
        meta: {title: 'H5学生报告'},
        hidden: true
      },
    ]
  },
  {
    path: '/exam',
    component: Layout,
    meta: {title: '考试', icon: 'exam'},
    children: [
      {
        path: 'official',
        name: 'officialExam',
        component: () => import('@/views/exam/official'),
        meta: {title: '机房考试'}
      },
      {
        path: 'official/officialList',
        name: 'officialList',
        component: () => import('@/views/exam/officialList'),
        meta: {title: '机房考试', activeMenu: '/exam/official'},
        hidden: true
      },
      {
        path: 'check',
        name: 'checkExam',
        component: () => import('@/views/exam/checkExam'),
        meta: {title: '线上模考'}
      },
      {
        path: 'assign',
        name: 'assignExam',
        component: () => import('@/views/exam/assignExam'),
        meta: {title: '创建考试', activeMenu: '/exam/check'},
        hidden: true
      },
      {
        path: 'check/examList',
        name: 'examList',
        component: () => import('@/views/exam/examList'),
        meta: {title: '考试列表', activeMenu: '/exam/check'},
        hidden: true
      },
      {
        path: 'check/examDetail',
        name: 'examDetail',
        component: () => import('@/views/exam/examDetail'),
        meta: {title: '查看报告', activeMenu: '/exam/check'},
        hidden: true
      },
      {
        path: 'official/classExamDetail',
        name: 'ClassExamDetail',
        component: () => import('@/views/exam/classExamDetail'),
        meta: {title: '考试报告', activeMenu: '/exam/official'},
        hidden: true
      },
      {
        path: 'official/studentReport',
        name: 'studentReport',
        component: () => import('@/views/exam/studentReport'),
        meta: {title: '考试详情', activeMenu: '/exam/official'},
        hidden: true
      },
    ]
  },
  {
    path: '/classes',
    component: Layout,
    redirect: '/classes/manage',
    meta: {title: '班级管理', icon: 'classes'},
    children: [
      {
        path: 'manage',
        name: 'ClassManage',
        component: () => import('@/views/classes/index'),
        meta: {title: '班级管理', icon: 'classes'},
      },
      {
        path: ':id',
        name: 'ClassDetails',
        component: () => import('@/views/classes/details'),
        meta: {title: '班级详情', activeMenu: '/classes/manage'},
        hidden: true
      },
      {
        path: ':id/group',
        name: 'ClassGroup',
        component: () => import('@/views/classes/group'),
        meta: {title: '分组管理', activeMenu: '/classes/manage'},
        hidden: true
      },
    ]
  },
  {
    path: '/report',
    component: Layout,
    meta: {title: '学情报告', icon: 'report'},
    children: [
      {
        path: 'work',
        name: 'workReport',
        component: () => import('@/views/report/workReport'),
        meta: {title: '作业报告'},
      },
      {
        path: 'work/grade',
        name: 'gradeWorkReport',
        component: () => import('@/views/report/work/gradeWorkReport'),
        meta: {title: '年级作业报告', activeMenu: '/report/work'},
        hidden: true
      },
      {
        path: 'work/classes',
        name: 'classWorkReport',
        component: () => import('@/views/report/work/classWorkReport'),
        meta: {title: '班级作业报告', activeMenu: '/report/work'},
        hidden: true
      },
      {
        path: 'work/user',
        name: 'userReport',
        component: () => import('@/views/report/work/userReport'),
        meta: {title: '个人作业报告', activeMenu: '/report/work'},
        hidden: true
      },
      {
        path: 'exam',
        name: 'examReport',
        component: () => import('@/views/report/examReport'),
        meta: {title: '模考报告'},
      },
      {
        path: 'exam/grade',
        name: 'gradeExamReport',
        component: () => import('@/views/report/exam/gradeExamReport'),
        meta: {title: '年级模考报告', activeMenu: '/report/exam'},
        hidden: true
      },
      {
        path: 'exam/classes',
        name: 'classExamReport',
        component: () => import('@/views/report/exam/classExamReport'),
        meta: {title: '班级模考报告', activeMenu: '/report/exam'},
        hidden: true
      },
      {
        path: 'exam/user',
        name: 'userExamReport',
        component: () => import('@/views/report/exam/userExamReport'),
        meta: {title: '个人模考报告', activeMenu: '/report/exam'},
        hidden: true
      },
    ]
  },
  {
    path: '/helper',
    component: Layout,
    meta: {title: '作业助手', icon: 'helper'},
    admin: true,
    children: [
      {
        path: 'userWork',
        name: 'userWork',
        component: () => import('@/views/helper/userWork'),
        meta: {title: '我的布置'}
      },
      {
        path: 'checkBySchool',
        name: 'checkBySchool',
        component: () => import('@/views/helper/checkBySchool'),
        meta: {title: '按学校查看'}
      },
      {
        path: 'checkByTeacher',
        name: 'checkByTeacher',
        component: () => import('@/views/helper/checkByTeacher'),
        meta: {title: '按教师查看'}
      },
      {
        path: 'workBySchool',
        name: 'workBySchool',
        component: () => import('@/views/helper/workBySchool'),
        meta: {title: '按学校查看-详情', activeMenu: '/helper/checkBySchool'},
        hidden: true
      },
    ]
  },
  {
    path: '/homework/workSubmit',
    name: 'workSubmit',
    component: () => import('@/views/homework/workSubmit'),
    meta: {title: '作业布置'},
    hidden: true
  },
  {
    path: '/exam/examWorkSubmit',
    name: 'examWorkSubmit',
    component: () => import('@/views/exam/examWorkSubmit'),
    meta: {title: '模考布置'},
    hidden: true
  },
  {
    path: '/down',
    component: Layout,
    redirect: '/down/center',
    meta: {title: '下载中心',icon: 'down'},
    children: [
      {
        path: 'center',
        name: 'Center',
        component: () => import('@/views/down/center'),
        meta: {title: '下载中心',icon: 'down'}
      },
    ]
  },
  {
    path: '/settings',
    name: 'manage',
    component: () => import('@/views/setting/manage'),
    meta: {title: '账号设置'},
    hidden: true
  },

  // 404 page must be placed at the end !!!
  {path: '*', redirect: '/404', hidden: true}
]

const router = new Router({
  routes: constantRoutes
})

// 全局路由守卫
router.beforeEach((to, form, next) => {
  let sessionId = sessionStorage.getItem('sessionId') // sessionId与token一样
  let yearSystem = sessionStorage.getItem('yearSystem') // 年制  0-三年制 1-四年制
  let schoolSection = sessionStorage.getItem('schoolSection') // 学段  1-初中 2-高中 3-完中

  if (to.name !== 'login' && to.name !== 'transfer') {
    if (!sessionId || (!yearSystem && yearSystem !== 0) || !schoolSection) {
      router.replace({name: 'login'})
    }
  }
  const {menuList} = utils.globalData
  const {name} = form
  const obj = {}
  obj.routerName = name
  obj.query = form.query
  obj.toName = to.name
  let flag = false
  for (let i = 0, len = menuList.length; i < len; i += 1) {
    const {child} = menuList[i]
    if (name === menuList[i].type) {
      flag = true
      obj.name = menuList[i].name
      break
    }
    if (child) {
      for (let j = 0, len = child.length; j < len; j += 1) {
        if (name === child[j].type) {
          flag = true
          obj.name = child[j].name
          break
        }
      }
      if (flag) break
    }
  }
  if (flag) {
    store.commit('routerClear')
  } else {
    obj.name = form.query.name || form.params.name || name
    if (['workSubmit', 'examWorkSubmit'].includes(obj.name)) {
      obj.name = '确认布置'
    }
  }
  if ('examList' !== obj.name) {
    // 获取教师类型 -- 1：普通教师；2：超级教师
    let superAdmin = sessionStorage.getItem('superAdmin')
    // 当登录人为普通教师时，不需要布置考试列表页面路由记录，不需要将学校列表页路由记录到store.js 的 routerList(面包屑展示用)
    if (obj.name === 'officialList') {
      if (superAdmin === '2') {
        store.commit('routerChange', obj)
      }
    } else if(obj.name !== 'assignExam') {
      store.commit('routerChange', obj)
    }
  }
  next()
})

export default router
