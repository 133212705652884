<template>
  <div v-if="menuPermission">
    <template
      v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
      <router-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}">
          <div class="sidebar-item">
            {{ onlyOneChild.meta.title }}
          </div>
        </el-menu-item>
      </router-link>
    </template>

    <el-submenu v-else ref="subMenu" popper-class="sub-menu" :index="resolvePath(item.path)">
      <template slot="title">
        <div class="sidebar-item" v-if="item.meta">
          {{ item.meta.title }}
          <img class="active" src="../../../assets/z-1.png" alt="">
          <img class="passive" src="../../../assets/spread-2.png" alt="">
        </div>
      </template>
      <nav-menu-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"/>
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'

export default {
  name: "NavMenuItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      onlyOneChild: null
    }
  },
  computed: {
    menuPermission() {
      let superAdmin = sessionStorage.getItem('superAdmin')
      if (this.item.admin) {
        return superAdmin === '2'
      } else {
        return !this.item.hidden
      }
    }
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          this.onlyOneChild = item
          return true
        }
      })

      if (showingChildren.length === 1) {
        return true
      }

      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath) {
      if (this.isExternal(routePath)) {
        return routePath
      }
      if (this.isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    },
    isExternal(path) {
      return /^(https?:|mailto:|tel:)/.test(path)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-submenu{
  &.is-active{
    .el-submenu__title{
      .sidebar-item {
        font-weight: bold;
        color: #309AF2;
      }
      .active{
        display: inherit;
      }
      .passive{
        display: none;
      }
    }
  }
  .el-submenu__title{
    &:hover{
      background-color: transparent;
      .sidebar-item {
        font-weight: bold;
        color: #309AF2;
      }
      .el-icon-arrow-down{
        color: #309AF2;
      }
      .active{
        display: inherit;
      }
      .passive{
        display: none;
      }
    }
    .active,.passive{
      margin-left: 7px;
    }
    .active{
      display: none;
    }
    .passive{
      display: inherit;
    }
    .el-submenu__icon-arrow{
      display: none;
    }
  }
  &.is-opened{

  }
}
.sidebar-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  img {
    width: 11px;
    height: 6px;
  }
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.icon-home {
  background-image: url("./icons/home.png");
}

.icon-homework {
  background-image: url("./icons/homework.png");
}

.icon-classes {
  background-image: url("./icons/classes.png");
}

.icon-exam {
  background-image: url("./icons/exam.png");
}

.icon-report {
  background-image: url("./icons/report.png");
}

.icon-helper {
  background-image: url("./icons/helper.png");
}

.icon-down {
  background-image: url("./icons/down.png");
}

</style>
<style lang="scss">
// 弹出框样式
.el-menu--horizontal.sub-menu{
  .el-menu{
    padding: 20px;
    min-width: 112px;
    border-radius: 16px;
    box-shadow: 0 4px 15px 0 rgba(0, 0%, 0%, 0.08);
    .nest-menu{
      &+.nest-menu{
        margin-top: 20px;
      }
    }
    .el-menu-item{
      padding: 0;
      font-size: 18px;
      height: 25px;
      line-height: 25px;
      color: #333333;
      &.is-active{
        font-weight: bold;
        background-color: #FFFFFF;
        color: #309AF2;
      }
      &:hover{
        font-weight: bold;
        background-color: #FFFFFF;
        color: #309AF2;
      }
    }
  }
}
</style>
